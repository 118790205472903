<template>    
    <div class="seismic-view-3d-root">
        <div class="button-container">
            <div></div>
            <div class="button-group" v-if="traceBounds">
                <label for="inlineSelect">{{ `Inline (${traceBounds.threeDeeRange.inlineRange.min} - ${traceBounds.threeDeeRange.inlineRange.max}):`}}</label>
                <input type="number" id="inlineSelect" name="inline" v-model.number="selectedInline" v-bind:min="traceBounds.threeDeeRange.inlineRange.min" v-bind:max="traceBounds.threeDeeRange.inlineRange.max" v-bind:step="traceBounds.threeDeeRange.inlineRange.step">
                <button v-on:click="addInline">Show</button>
                <button v-on:click="hideInline">Hide</button>
            </div>
            <div class="button-group" v-if="traceBounds">
                <label for="xlineSelect">{{ `Crossline (${traceBounds.threeDeeRange.crosslineRange.min} - ${traceBounds.threeDeeRange.crosslineRange.max}):`}}</label>
                <input type="number" id="xlineSelect" name="xline" v-model.number="selectedXline" v-bind:min="traceBounds.threeDeeRange.crosslineRange.min" v-bind:max="traceBounds.threeDeeRange.crosslineRange.max" v-bind:step="traceBounds.threeDeeRange.crosslineRange.step">
                <button v-on:click="addxLine">Show</button>
                <button v-on:click="hidexLine">Hide</button>

                <!--<button v-on:click="camera">Camera</button>-->
            </div>
            <div></div>

            <!-- Hide color selector for now, it seems to re-request the data. (doesn't in 2d though, why?) -->
            <!--<div class="button-group" v-if="traceBounds">
                <span>Color Map</span>
                <select class="color-select" v-model="selectedColorMap" id="colorMap" @change="onColorChangedEvent()">
                    <option v-for="option in colorOptions" v-bind:key="option.name" v-bind:value="option.name">{{ option.name }}</option>
                </select>
            </div>-->
        </div>
    
        <div ref="plotContainer3D" class="plot-container-3d">            
        </div>
    </div>
</template>

<style>
    .seismic-view-3d-root {
        background-color: #dbdcdd;
        display: grid;
        grid-template-rows: 40px auto;        
    }

    .button-container {
        display: grid;
        grid-template-columns: auto min-content min-content auto;
        grid-column-gap: 2em;
        align-items: center;
    }

    .button-group {
        display: inline;
        white-space: nowrap;
    }
</style>


<script lang="ts">

    import { defineComponent } from 'vue';
    import * as Constants from "../common/Constants";
    import { Store } from "../common/AppState";
    import { TraceBoundsDto } from "../dto/TraceBoundsDto";
    import { StatisticsProcessor } from "../trace-processors/StatisticsProcessor";
    import { getMemoryReaderForSlice } from "../readers/Seismic3dMemoryReader";
    
    // INT libraries    
    import { SeismicColors } from "@int/geotoolkit/seismic/util/SeismicColors";
    import { RasterProperties } from "@/common/RasterProperties";
    import { NormalizationType } from "@int/geotoolkit/seismic/pipeline/NormalizationType";
    import { Plot } from "@int/geotoolkit3d/Plot";
    import { Grid, Mode } from "@int/geotoolkit3d/scene/grid/Grid";
    import { IndexGrid } from "@int/geotoolkit3d/scene/seismic/IndexGrid";
    import { CompassAxis } from "@int/geotoolkit3d/scene/compass/CompassAxis";

    import { Slice } from "@int/geotoolkit3d/scene/seismic/Slice";
    import { Volume } from "@int/geotoolkit3d/scene/seismic/Volume";
    import { SliceMaterial } from "@int/geotoolkit3d/scene/seismic/SliceMaterial";
    import { IndexCoordinates } from "@int/geotoolkit3d/transformation/IndexCoordinates";
    import { XYCoordinates } from "@int/geotoolkit3d/transformation/XYCoordinates";
    import { CrossCursor } from "@int/geotoolkit3d/tool/cursor/CrossCursor";
    import { CursorTool } from "@int/geotoolkit3d/tool/cursor/CursorTool";
    import { TextStyle } from "@int/geotoolkit/attributes/TextStyle";
    import { LineStyle } from "@int/geotoolkit/attributes/LineStyle";


    import { MemoryReader } from "@int/geotoolkit/seismic/data/MemoryReader";
    import * as PgsProvider from "../data-providers/PgsProvider";
    import * as TgsProvider from "../data-providers/TgsProvider";

    import { Vector3, Mesh, MeshBasicMaterial, SphereGeometry } from "@int/geotoolkit3d/THREE";
    import { SeismicModes } from '@int/geotoolkit3d/Constants';
    import { Type as EventType } from '@int/geotoolkit3d/Event';
    import { SliceType } from '../common/SliceType';



    let plotContainer: HTMLDivElement;

    let plot: Plot;
    let seismicVolume: Volume = null;
    let inlineSlice: Slice = null;
    let xlineSlice: Slice = null;
    
    // Not used?
    function updatePlotRasterProperty(rasterProperties: RasterProperties): void {
        if (inlineSlice && inlineSlice.getSliceMaterial() != null && inlineSlice.getSliceMaterial().getPipeline() != null) {
            inlineSlice.getSliceMaterial().getPipeline().setOptions({
                plot: {
                    type: {
                        Wiggle: rasterProperties.wiggle,
                        InterpolatedDensity: rasterProperties.interpolatedDensity,
                        PositiveFill: rasterProperties.positiveFill,
                        NegativeFill: rasterProperties.negativeFill,
                        PositiveColorFill: rasterProperties.positiveColorFill,
                        NegativeColorFill: rasterProperties.negativeColorFill
                    }
                }
            });
        }
    }

    // Not used. But can turn on for reference. We are adding an indexGrid instead.
    function createGrid(numberOfInlines: number, numberOfXlines: number, z: number): Grid {
        const mainGrid = new Grid({
            counts: new Vector3(3, 3, 3),
            start: new Vector3(0, 0, -z),
            end: new Vector3(numberOfInlines * 2, numberOfXlines * 2, 0),
            //start: new Vector3(0, 0, -4000),
            //end: new Vector3(20000, 20000, 0),
            //modelStart: new Vector3(38660, 58524, 4000),
            //modelEnd: new Vector3(46393, 68453, 0),
            title: {
                texts: {
                    x: 'X',
                    y: 'Y',
                    z: 'Depth'
                },
                textstyles: {
                    'x': new TextStyle({
                        'color': 'grey',
                        'font': '12px Arial'
                    }),
                    'y': new TextStyle({
                        'color': 'grey',
                        'font': '12px Arial'
                    }),
                    'z': new TextStyle({
                        'color': 'grey',
                        'font': '12px Arial'
                    })
                }
            },
            axis: {
                linestyles: {
                    'x': new LineStyle('grey'),
                    'y': new LineStyle('grey'),
                    'z': new LineStyle('grey')
                },
                textstyles: {
                    'x': new TextStyle({
                        'color': 'grey',
                        'font': '11px Arial'
                    }),
                    'y': new TextStyle({
                        'color': 'grey',
                        'font': '11px Arial'
                    }),
                    'z': new TextStyle({
                        'color': 'grey',
                        'font': '11px Arial'
                    })
                }
            }
        });
        return mainGrid;
    }

    function enablePlotLogging(plot: Plot): void {

        plot.on(EventType.Add, (x: any, y: any, z: any) => {
            console.log(`Plot Add Event`);
        });
        plot.on(EventType.Remove, () => console.log(`Plot Remove Event`));
        plot.on(EventType.Resize, () => console.log(`Plot Resize Event`));
        //plot.on(EventType.BeforeRender, () => console.log(`Plot BeforeRender Event`));
        //plot.on(EventType.AfterRender, () => console.log(`Plot AfterRender Event`));
        plot.on(EventType.ModelLimits, () => console.log(`Plot ModelLimits Event`));
        plot.on(EventType.Invalidate, () => console.log(`Plot Invalidate Event`));
        plot.on(EventType.RenderError, () => console.log(`Plot RenderError Event`));
        plot.on(EventType.Camera, () => console.log(`Plot Camera Event`));
        plot.on(EventType.DuringRender, () => console.log(`Plot DuringRender Event`));
    }


    export default defineComponent({
        name: 'SeismicView3D',
        components: {
        },
        props: {
            provider: String,
            productId: String,
            projectId: String
        },
        data() {
            return {
                selectedInline: 0,
                selectedXline: 0,
                traceBounds: null,
                colorOptions: Constants.colorMap.map(obj => ({ ...obj })),
                selectedColorMap: Constants.DEFAULT_COLOR_MAP,
            };
        },

        created() {
            
        },

        unmounted() {
            clear();
        },

        mounted() {

            // event is local, not from event man
            //EventManager.on("ColorMapChanged", (color) => this.onColorChangedEvent(color as string));


            // Setup the plot, initial camera placement, axis and cursor

            // Grab the HTML Elements we need
            plotContainer = this.$refs["plotContainer3D"] as HTMLDivElement;

            // Instantiate a 3D plot
            plot = new Plot({
                container: plotContainer,
                camera: {
                    position: new Vector3(3700, 3450, 1000),
                    lookat: new Vector3(0, 0, 0)
                },
                renderer: {
                    clearcolor: "black"
                }
            });
            

            // Retrieves the compass container
            const compass = plot.getCompass();
            // Axis compass looks nicer     
            compass.setCompassObject(new CompassAxis());

            // Add cursor
            const cursor = new CursorTool({})
                .setOptions({
                    'cursor': new CrossCursor({})
                });

            plot.addTool(cursor);


            // Add spere at center for reference
            const centerCircleMaterial = new MeshBasicMaterial({ color: "#FF0000" });
            const centerCircleGeometry = new SphereGeometry(1);
            const centerCircleMesh = new Mesh(centerCircleGeometry, centerCircleMaterial);
            centerCircleMesh.scale.set(10, 10, 10);
            centerCircleMesh.position.set(0, 0, 0);
            centerCircleMesh.name = "Center Sphere";

            plot.getRoot().add(centerCircleMesh);

            const msgToken = Store.addBusyMessage(`Retrieving 3D trace bounds`);

            switch (this.provider) {
                case "PGS":
                    PgsProvider.getTraceBounds(this.productId).then((bounds: TraceBoundsDto) => {
                        Store.removeBusyMessage(msgToken);
                        console.log(bounds);

                        this.traceBounds = bounds;

                        // Set selected inline / xline in the UI to some slice in the middle
                        this.selectedInline = Math.floor((bounds.threeDeeRange.inlineRange.max - bounds.threeDeeRange.inlineRange.min) / 2) + bounds.threeDeeRange.inlineRange.min;
                        this.selectedXline = Math.floor((bounds.threeDeeRange.crosslineRange.max - bounds.threeDeeRange.crosslineRange.min) / 2) + bounds.threeDeeRange.crosslineRange.min;

                        createVolume(bounds);
                    });
                    break;

                case "TGS":
                    TgsProvider.getTraceBounds(this.productId, this.projectId, null).then((bounds: TraceBoundsDto) => {
                        Store.removeBusyMessage(msgToken);
                        console.log(bounds);

                        this.traceBounds = bounds;

                        // Set selected inline / xline in the UI to some slice in the middle
                        this.selectedInline = Math.floor((bounds.threeDeeRange.inlineRange.max - bounds.threeDeeRange.inlineRange.min) / 2) + bounds.threeDeeRange.inlineRange.min;
                        this.selectedXline = Math.floor((bounds.threeDeeRange.crosslineRange.max - bounds.threeDeeRange.crosslineRange.min) / 2) + bounds.threeDeeRange.crosslineRange.min;

                        createVolume(bounds);
                    });
                    break;

                default:
                    console.error(`Provider '${this.provider}' not supported.`);
                    return Promise.reject(`Provider '${this.provider}' not supported.`);

            }

            //enablePlotLogging(plot);
        },

        methods: {
            camera(): void {
                console.log(plot.getCameraLocation());
            },

            onColorChangedEvent(): void {
                if (inlineSlice?.getSliceMaterial()?.getPipeline()) {
                    inlineSlice.getSliceMaterial().getPipeline().setOptions({
                        colors: {
                            colorMap: SeismicColors.getDefault().createNamedColorMap(this.selectedColorMap)
                        }
                    });
                }

                if (xlineSlice?.getSliceMaterial()?.getPipeline()) {
                    xlineSlice.getSliceMaterial().getPipeline().setOptions({
                        colors: {
                            colorMap: SeismicColors.getDefault().createNamedColorMap(this.selectedColorMap)
                        }
                    });
                }
            },
                       
            hideInline(): void {
                if (inlineSlice) {
                    inlineSlice.visible = false;
                    inlineSlice.invalidateObject();
                }
            },

            addInline() {
                createSlice(this.provider, this.productId, inlineSlice, SliceType.INLINE, this.selectedInline, this.projectId).then(newSlice => {
                    inlineSlice = newSlice;
                });
            },

            hidexLine(): void {
                if (xlineSlice) {
                    xlineSlice.visible = false;
                    xlineSlice.invalidateObject();
                }
            },

            addxLine(): void {
                createSlice(this.provider, this.productId, xlineSlice, SliceType.CROSSLINE, this.selectedXline, this.projectId).then(newSlice => {
                    xlineSlice = newSlice;
                });
            }
        }
    });

    function createSlice(provider: string, productId: string, slice: Slice, sliceType: SliceType, sliceLocation: number, projectId: string): Promise<Slice> {

        return new Promise((resolve) => {

            // Dispose of old slice
            if (slice) {

                // If the user clicked the show button again, but hasn't changed the line. Just turn the slice back on
                if (slice.userData.selectedLine == sliceLocation) {
                    slice.visible = true;
                    slice.invalidateObject();
                    return;
                }

                // Remove the current slice before creating a new one
                seismicVolume.remove(slice);
                slice.dispose();
                slice = null;
            }

            getMemoryReaderForSlice(provider, productId, sliceType, sliceLocation, projectId).then((reader: MemoryReader) => {

                const material = createSliceMaterial(reader);

                // Stats processor captures trace statistics and updates normalization limits as data comes it.
                // If we set normalization limits in the await callback it triggers a re-download of trace data
                const statsProcessor = new StatisticsProcessor({ "apply": true, name: "StatisticsProcessor" });
                material.getPipeline().addTraceProcessor(statsProcessor);
                
                material.getPipeline().await(() => {
                    console.log(`${sliceType == SliceType.INLINE ? "INLINE" : "CROSSLINE"} fetch complete`);

                    // Just for debuging, Fires a print to console
                    statsProcessor.getDataStatistics();
                });

                const sliceLocationOption = sliceType == SliceType.INLINE ? { i: sliceLocation } : { j: sliceLocation };

                // Create and add a seismic slice
                const newSlice = new Slice({
                    material: material,
                    index: seismicVolume.getIndexCoordinates(),
                    slicelocation: sliceLocationOption
                });

                newSlice.userData.selectedLine = sliceLocation;
                seismicVolume.add(newSlice);

                plot.invalidateObject();

                resolve(newSlice);
            });
        });
    }

    function createSliceMaterial(reader: MemoryReader): SliceMaterial {

        const material = new SliceMaterial({
            data: {
                reader: reader
            },
            //clearpipeline: true,
            oninvalidate: seismicVolume.invalidateObject.bind(seismicVolume)
        });

        material.getPipeline().setOptions({
            plot: {
                type: {
                    Wiggle: Constants.DEFAULT_RASTER_PROPERTIES.wiggle,
                    InterpolatedDensity: Constants.DEFAULT_RASTER_PROPERTIES.interpolatedDensity,
                    PositiveFill: Constants.DEFAULT_RASTER_PROPERTIES.positiveFill,
                    NegativeFill: Constants.DEFAULT_RASTER_PROPERTIES.negativeFill,
                    PositiveColorFill: Constants.DEFAULT_RASTER_PROPERTIES.positiveColorFill,
                    NegativeColorFill: Constants.DEFAULT_RASTER_PROPERTIES.negativeColorFill
                },
                densityDecimation: true,
                decimationSpacing: 1
            },
            colors: {
                colorMap: SeismicColors.getDefault().createNamedColorMap("GreyOrange")
            },
            normalization: {
                type: NormalizationType.Limits
            }
        });

        return material;
    }

    /** Clean up and dispose of everything. */
    function clear(): void {

        if (!!plot) {

            if (seismicVolume != null) {

                if (xlineSlice) {
                    seismicVolume.remove(xlineSlice);
                    xlineSlice.dispose();
                    xlineSlice = null;
                }

                if (inlineSlice) {
                    seismicVolume.remove(inlineSlice);
                    inlineSlice.dispose();
                    inlineSlice = null;
                }

                plot.getRoot().remove(seismicVolume);
                seismicVolume = null;
            }

            plot.invalidateObject();
        }
    }

    function createVolume(bounds: TraceBoundsDto): void {
        const numberOfInlines = (bounds.threeDeeRange.inlineRange.max - bounds.threeDeeRange.inlineRange.min) / bounds.threeDeeRange.inlineRange.step;
        const numberOfXlines = (bounds.threeDeeRange.crosslineRange.max - bounds.threeDeeRange.crosslineRange.min) / bounds.threeDeeRange.crosslineRange.step;

        const inlinexline = { // Native coordinates are in inline/crossline
            i0: bounds.threeDeeRange.inlineRange.min, icount: numberOfInlines, istep: bounds.threeDeeRange.inlineRange.step, // first inline: 170, last inline: 540
            j0: bounds.threeDeeRange.crosslineRange.min, jcount: numberOfXlines, jstep: bounds.threeDeeRange.crosslineRange.step, // first xline: 1170, last xline: 1440
            z0: 0, samplecount: bounds.sampleCount, samplerate: 1 // First z: 0, last z: 4000 
        }
        
        // Don't really understand this whole index to xy coordinate mapping thing, it's not documented well.
        // If using SeismicModes.ij does it even matter?   

        // const xy = { // Display coordinates are in XY
        //    x0: 0, y0: 0, // corner 0: first inline & first xline (i0,j0)
        //    x1: 4000, y1: 0, // corner 1: last inline & first xline (i1,j0)
        //    x2: 4000, y2: 4000 // corner 2: last inline & last xline (i1,j1)
        // };

        const xy = { // Display coordinates are in XY
            x0: 0, y0: 0, // corner 0: first inline & first xline (i0,j0)
            x1: numberOfInlines * 2, y1: 0, // corner 1: last inline & first xline (i1,j0)
            x2: numberOfInlines * 2, y2: numberOfXlines * 2 // corner 2: last inline & last xline (i1,j1)
        };

        const indexCoordinates = new IndexCoordinates().fromJSONIndex(inlinexline);

        // Creates a seismic volume object that holds the inlinexline to xy transformation
        seismicVolume = new Volume({
            index: indexCoordinates,
            xy: new XYCoordinates().fromJSONXY(xy),
            displaymode: SeismicModes.ij
        });

        seismicVolume.name = "seismicVolume";
        seismicVolume.position.set(0, 0, 0);

        const grid = new IndexGrid({
            counts: new Vector3(5, 5, 3),
            index: indexCoordinates,
            title: {
                texts: {
                    x: "Inline",
                    y: "Crossline",
                    z: "Depth"
                },
                textstyles: {
                    'x': new TextStyle({
                        'color': 'red',
                        'font': '12px Arial'
                    }),
                    'y': new TextStyle({
                        'color': 'green',
                        'font': '12px Arial'
                    }),
                    'z': new TextStyle({
                        'color': 'grey',
                        'font': '12px Arial'
                    })
                }
            },
            axis: {
                linestyles: {
                    'x': new LineStyle('grey'),
                    'y': new LineStyle('grey'),
                    'z': new LineStyle('grey')
                },
                textstyles: {
                    'x': new TextStyle({
                        'color': 'red',
                        'font': '11px Arial'
                    }),
                    'y': new TextStyle({
                        'color': 'green',
                        'font': '11px Arial'
                    }),
                    'z': new TextStyle({
                        'color': 'grey',
                        'font': '11px Arial'
                    })
                }
            },
            grid: {
                linestyles: {
                    'x': new LineStyle('grey'),
                    'y': new LineStyle('grey'),
                    'z': new LineStyle('grey')
                }
            },
            mode: Mode.openbox
        });

        seismicVolume.add(grid);

        // Add the volume to the plot
        plot.getRoot().add(seismicVolume);

        // Add main grid
        // const mainGrid = this.createGrid(numberOfInlines, numberOfXlines, details.sampleCount);
        // mainGrid.position.set(0, 0, 0);
        // plot.getRoot().add(mainGrid);

        plot.setCameraLookAt(seismicVolume.position, true, 500);
        plot.fitCamera(null, true, 500);
    }

</script>