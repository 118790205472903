import { createApp } from 'vue';
import App from './App.vue';
import { isUserSignedIn } from "./common/CvxAuth";

const app = createApp(App);

console.log(`NODE_ENV = ${process.env.NODE_ENV}`);

//app.mount("#app");

// Sign in using CAL before mounting the app
isUserSignedIn().then(isUserSignedIn => {
    if (isUserSignedIn) {
        // app.config.globalProperties.xxx = xxx; 
        app.mount("#app");
    }
});






