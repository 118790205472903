<template>
    <div class="toolbar-root">      
        
        <div class="toolbar-group">
            <ul>
                <li>            
                    <button class="toolbar-button" title="Zoom In" v-on:click="zoomIn">
                        <img alt=" " src="../assets/tools/zoom-in-24.png">
                        <p class="tool-label">Zoom In</p>
                    </button>
                </li>
                <li>            
                    <button class="toolbar-button" title="Zoom Out" v-on:click="zoomOut">
                        <img alt=" " src="../assets/tools/zoom-out-24.png">
                        <p class="tool-label">Zoom Out</p>
                    </button>
                </li>
                <li>            
                    <button class="toolbar-button" title="Zoom to Area" v-on:click="rubberBandZoom">
                        <img alt=" " src="../assets/tools/zoom-extent-24.png">
                        <p class="tool-label">Zoom to Area</p>
                    </button>
                </li>
                <li>            
                    <button class="toolbar-button" title="Reset Zoom" v-on:click="zoomReset">
                        <img alt=" " src="../assets/tools/zoom-initial-24.png">
                        <p class="tool-label">Reset Zoom</p>
                    </button>
                </li>
                <li>            
                    <button class="toolbar-button" title="Zoom to Fit" v-on:click="zoomToFit">
                        <img alt=" " src="../assets/tools/zoom-full-24.png">
                        <p class="tool-label">Zoom to Fit</p>
                    </button>
                </li>                
            </ul>
        </div>

        <div class="toolbar-group">
            <ul>
                <li> 
                    <p class="tool-label">Color Map</p>       
                    <select class="color-select" v-model="selectedColorMap" id="colorMap" @change="onColorChange()">
                        <option v-for="option in colorOptions" v-bind:key="option.name" v-bind:value="option.name">{{ option.name }}</option>
                    </select>                    
                </li>
            </ul>
        </div>

        <div class="toolbar-group">
            <ul>
                <li class="checkbox-group"> 
                    <div class="checkbox-item">                         
                        <input type="checkbox" id="box1" v-model="rasterProperties.interpolatedDensity" @change="rasterPropertyChanged()">
                        <label class="checkbox-label" for="box1">Interpolated Density</label>   
                    </div>
                    <div class="checkbox-item">
                        <input type="checkbox" id="box2" v-model="rasterProperties.positiveFill" @change="rasterPropertyChanged()">                  
                        <label class="checkbox-label" for="box2">Positive Fill</label>
                    </div>
                    <div class="checkbox-item">
                        <input type="checkbox" id="box3" v-model="rasterProperties.positiveColorFill" @change="rasterPropertyChanged()">                  
                        <label class="checkbox-label" for="box3">Positive Color Fill</label>
                    </div>
                    <div class="checkbox-item">
                        <input type="checkbox" id="box4" v-model="rasterProperties.wiggle" @change="rasterPropertyChanged()">                  
                        <label class="checkbox-label" for="box4">Wiggles</label>
                    </div>
                    <div class="checkbox-item">
                        <input type="checkbox" id="box5" v-model="rasterProperties.negativeFill" @change="rasterPropertyChanged()">                  
                        <label class="checkbox-label" for="box5">Negative Fill</label>
                    </div>
                    <div class="checkbox-item">
                        <input type="checkbox" id="box6" v-model="rasterProperties.negativeColorFill" @change="rasterPropertyChanged()">                  
                        <label class="checkbox-label" for="box6">Negative Color Fill</label>
                    </div>
                </li>
            </ul>
        </div>

        <div class="toolbar-group">
            <ul>
                
                <li>            
                    <button class="toolbar-button" title="scale Y Down" v-on:click="scaleYDown">
                        <img alt=" " src="../assets/tools/chevron-down-24.png">
                        <p class="tool-label">Decrease</p>
                    </button>
                </li>
                <li> 
                    <p class="tool-label">Vertical Scale</p>       
                    <span class="tool-text-below-label"> {{ scaleYValue }} </span>                   
                </li>                
                <li>            
                    <button class="toolbar-button" title="scale Y Up" v-on:click="scaleYUp">
                        <img alt=" " src="../assets/tools/chevron-up-24.png">
                        <p class="tool-label">Increase</p>
                    </button>
                </li>  
                <li>            
                    <button class="toolbar-button" title="scaleReset" v-on:click="scaleReset">
                        <img alt=" " src="../assets/tools/zoom-initial-24.png">
                        <p class="tool-label">Reset</p>
                    </button>
                </li>              
            </ul>
        </div>

    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { EventManager } from "../events/EventManager";
    import * as Constants from "../common/Constants";

    export default defineComponent({
        name: 'ToolbarView',
        components: {
        },

        data() {
            return {
                selectedColorMap: Constants.DEFAULT_COLOR_MAP,
                colorOptions: Constants.colorMap.map(obj => ({ ...obj })),
                rasterProperties: { ...Constants.DEFAULT_RASTER_PROPERTIES },
                scaleYValue: "-"
            };
        },

        mounted() {
            EventManager.on("SeismicWidgetScaleChanged", (value) => this.scaleChanged((value as number)));
        },

        methods: {
            scaleChanged(value: number): void {

                if (Number.isNaN(value)) {
                    this.scaleYValue = "NaN";
                    return;
                }

                this.scaleYValue = `${Math.round(value * 100) / 100} in/s`;
            },

            zoomIn(): void {
                EventManager.emit("ZoomInToolClicked");
            },

            zoomOut(): void {
                EventManager.emit("ZoomOutToolClicked");
            },

            rubberBandZoom(): void {
                EventManager.emit("RubberBandZoomToolClicked");
            },

            zoomReset(): void {
                EventManager.emit("ZoomResetToolClicked");
            },

            zoomToFit(): void {
                EventManager.emit("ZoomFitToolClicked");
            },

            onColorChange(): void {
                EventManager.emit("ColorMapChanged", this.selectedColorMap);
            },

            rasterPropertyChanged(): void {
                EventManager.emit("RasterPropertyChanged", this.rasterProperties);
            },


            scaleYDown(): void {
                EventManager.emit("scaleYClicked", false);
            },

            scaleYUp(): void {
                EventManager.emit("scaleYClicked", true);
            },

            scaleReset(): void {
                EventManager.emit("scaleResetClicked");
            }
        }

    });
</script>


<style>

    .toolbar-root {
        background-color: white;
        flex: 0 0 50px;
        display: flex;
        flex-wrap: wrap;
    }

    .toolbar-group {
        border: 1px solid #F0F0F0;
        border-radius: 0.25rem;
        display: flex;
        margin: 1px;
    }

    .toolbar-group ul {
        list-style: none;
        padding-inline-start: 0;
        margin-block-start: 0;
        margin-block-end: 0;
        display: flex;
    }

    .toolbar-group li {
        margin: 2px;
        min-width: 50px;
    }

    .toolbar-button {
        display: inline-block;
        border-radius: 0.25rem;
        border: 1px solid transparent;
        cursor: pointer;
        outline: none;
        height: 100%;
        width: 100%;
        background: transparent;
    }

    .toolbar-button:hover {        
        border: 1px solid #1A72C4;
        background: #E9F6FF;
    }

    .tool-label {
        clear: both;
        color: #000000bb;
        font-size: 0.8em;
        white-space: pre-wrap;
        margin: 0 0.5em;
        line-height: 1.25em;
    }

    .tool-text-below-label {
        clear: both;
        color: #000000bb;
        font-size: 0.8em;
        white-space: pre-wrap;
        margin: 0 0.5em;
        line-height: 1.25em;
    }

    .color-select {
        margin: 0 5px;
    }

    .checkbox-group {
        display: flex;
        flex-wrap: wrap;
        width: 440px;
        align-content: space-between;
    }

    .checkbox-item {
        width: 9em;
        display: flex;
        flex-wrap: nowrap;
        align-content: center;
    }

    .checkbox-item label
    {
        cursor: pointer;
        user-select: none;
    }

    .checkbox-item input {
        cursor: pointer;
    }
    

    .checkbox-label {
        clear: both;
        color: #000000bb;
        font-size: 0.8em;
        white-space: pre-wrap;
        line-height: 1.25em;
        align-self: center;
    }
</style>