import { MemoryReader } from "@int/geotoolkit/seismic/data/MemoryReader";
import { SliceType } from "../common/SliceType";


import { createMemoryReaderForSlice as createPgsMemoryReaderForSlice } from "./Pgs3dMemoryReader";
import { createMemoryReaderForSlice as createTgsMemoryReaderForSlice } from "./Tgs3dMemoryReader";


export function getMemoryReaderForSlice(provider: string, productId: string, sliceType: SliceType, sliceLocation: number, projectId: string): Promise<MemoryReader> {

    switch (provider) {
        case "PGS":
            return createPgsMemoryReaderForSlice(productId, sliceType, sliceLocation);
        case "TGS":
            return createTgsMemoryReaderForSlice(productId, sliceType, sliceLocation, projectId);
        default:
            console.error(`Provider '${provider}' not supported.`);
            return Promise.reject(`Provider '${provider}' not supported.`);
    }
}
