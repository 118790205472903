import{ config } from "appConfig";
import { CalClient, Config as CalConfig } from '@cvx/cal';

const calConfig = {
  autoSignIn: true,
  popupForLogin: false,
  cacheLocation: "localStorage",
  instance: "https://login.microsoftonline.com/",
  tenantId: "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  clientId: config.clientId,
  redirectUri: `${window.location.origin}${window.location.pathname}`,
  oidcScopes: ["openid", "profile", "User.Read", "offline_access"],
  graphScopes: ["User.Read", "User.Read.All", "Directory.Read.All"]
} as CalConfig

// Initialize Cal
const _cal = new CalClient(calConfig);

export function isUserSignedIn(): Promise<boolean> {    

    const signInPromise = _cal.isUserSignedIn();

    signInPromise.then(loggedIn => {
        if (loggedIn) {
            console.log(`CalClient: isUserSignedIn TRUE `);
        } else {
            console.log(`CalClient: isUserSignedIn FALSE `);
        }
    }).catch(e => {
        console.log(`CalClient: isUserSignedIn failed `);
    });

    return signInPromise;
}

export function getUserName(): string {
    return _cal?.cvxClaimsPrincipal?.userName;
}


export function getAadTokenForResource(resource: string): Promise<string> {    

    return new Promise((resolve, reject) => {

        _cal.isUserSignedIn().then((loggedIn: boolean) => {

            if (loggedIn) {                
                _cal.getAADToken([resource]).then((token) => {
                    resolve(token.toString());
                }).catch((err: Error) => {
                    console.log(`getAADToken ${err}`);
                    reject(err);
                });
            } else {
                console.log("User is not logged in with CAL");
            }
        }).catch((e: Error) => {
            console.log(`CalClient: isUserSignedIn failed`, e);
            reject(e);
        });
    });
}