import { config } from "appConfig";
import { TraceBoundsDto } from "../dto/TraceBoundsDto";
import { TraceDto } from "../dto/TraceDto";
import { getAadTokenForResource } from "../common/CvxAuth";


export function getTraceBounds(productId: string, projectId: string, lineName: string): Promise<TraceBoundsDto> {
    return new Promise((resolve, reject) => {

        getAadTokenForResource(config.tgsWrapperScope).then(token => {

            const url = `${config.tgsApi}/GetTraceBounds?productId=${productId}&projectId=${projectId}&lineName=${lineName}`;

            const requestOptions = {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
                method: 'GET'
            };

            fetch(url, requestOptions)
                .then((res: Response) => {
                    if (!res.ok) {
                        return Promise.reject(`${res.status}-${res.statusText}`);
                    }
                    return res.json();
                })
                .then((results: TraceBoundsDto) => {
                    resolve(results);
                })
                .catch(err => {
                    console.log(`getTraceBounds Failed: ${err}`);
                    reject(err);
                });
        });
    });
}

export function getTraces2d(productId: string, from: number, to: number, step: number, projectId: string, lineName: string): Promise<TraceDto[]> {

    const url = `${config.tgsApi}/GetTraces2d`;

    return new Promise((resolve, reject) => {

        getAadTokenForResource(config.tgsWrapperScope).then(token => {

            const postData = {
                projectId: projectId,
                productId: productId,
                from: from,
                to: to,
                step: step,
                lineName: lineName
            };

            const requestOptions = {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
                method: 'POST',
                body: JSON.stringify(postData)
            };

            fetch(url, requestOptions)
                .then((res: Response) => {
                    if (!res.ok) {
                        return Promise.reject(`${res.status}-${res.statusText}`);
                    }
                    return res.json();
                })
                .then((results: any) => {
                    resolve(results);
                })
                .catch(err => {
                    console.log(`getTraces2d Failed: ${err}`);
                    reject(err);
                });
        });
    });    
}


export function get3dSlice(productId: string, from: number, to: number, step: number, sliceType: "INLINE" | "CROSSLINE", sliceLocation: number, projectId: string): Promise<TraceDto[]> {

    const url = `${config.tgsApi}/Get3dSlice`;

    return new Promise((resolve, reject) => {

        getAadTokenForResource(config.tgsWrapperScope).then(token => {

            const postData = {
                projectId: projectId,
                productId: productId,
                from: from,
                to: to,
                step: step,
                sliceType: sliceType,
                sliceLocation: sliceLocation
            };

            const requestOptions = {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
                method: 'POST',
                body: JSON.stringify(postData)
            };

            fetch(url, requestOptions)
                .then((res: Response) => {
                    if (!res.ok) {
                        return Promise.reject(`${res.status}-${res.statusText}`);
                    }
                    return res.json();
                })
                .then((results: any) => {
                    resolve(results);
                })
                .catch(err => {
                    console.log(`get3dSlice Failed: ${err}`);
                    reject(err);
                });
        });
    });
}