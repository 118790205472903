
/**
* Generates a random alphanumeric token, given a length.
* @param length The length of the token to generate. If no length is provided, a default length of 8 characters is used.
*/
export function alphaNumericToken(length?: number): string {
    const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    const stringLength = length || 8;
    let randomstring = "";

    for (let i = 0; i < stringLength; i++) {
        const rnum = Math.floor(Math.random() * chars.length);
        randomstring += chars.substring(rnum, rnum + 1);
    }

    return randomstring;
}
