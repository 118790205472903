<template>
    <div class="spinner-container">
        <img class="spinner-image" src="../assets/loading.gif" v-show="Store.isBusy" alt="spinner...">
        <span class="spinner-msg">{{ Store.busyMessage }}</span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { Store } from "../common/AppState";

export default defineComponent({
    name: 'BusySpinner',
    data() {
        return {            
            Store
        };
    }
});


</script>

<style>
    .spinner-container {
        display: grid;
        grid-template-columns: min-content auto;
        align-content: center;
        justify-content: left;
    }

    .spinner-image {
        width: 30px;
        height: 15px;
    }

    .spinner-msg {
        white-space: nowrap;
        font-size: 0.9em;
    }

</style>