import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "app",
  class: "app-root"
}
const _hoisted_2 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderView = _resolveComponent("HeaderView")!
  const _component_SeismicView = _resolveComponent("SeismicView")!
  const _component_SeismicView3D = _resolveComponent("SeismicView3D")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderView),
    (_ctx.show2d)
      ? (_openBlock(), _createBlock(_component_SeismicView, {
          key: 0,
          provider: _ctx.provider,
          productId: _ctx.productId,
          projectId: _ctx.projectId,
          lineName: _ctx.lineName
        }, null, 8, ["provider", "productId", "projectId", "lineName"]))
      : _createCommentVNode("", true),
    (_ctx.show3d)
      ? (_openBlock(), _createBlock(_component_SeismicView3D, {
          key: 1,
          provider: _ctx.provider,
          productId: _ctx.productId,
          projectId: _ctx.projectId
        }, null, 8, ["provider", "productId", "projectId"]))
      : _createCommentVNode("", true),
    (_ctx.errorMsg)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.errorMsg), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}