import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "seismic-view-root" }
const _hoisted_2 = {
  ref: "plotContainer",
  class: "plot-container"
}
const _hoisted_3 = {
  ref: "seismicViewCanvas",
  class: "seismic-view-canvas"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToolbarView = _resolveComponent("ToolbarView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ToolbarView),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("canvas", _hoisted_3, null, 512)
    ], 512)
  ]))
}