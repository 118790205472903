import { reactive } from 'vue'
import { alphaNumericToken } from "../utils/stringUtils";

export interface AppState {

    // Todo: make read only
    isBusy: boolean;

    // Todo: make read only
    busyMessage: string;

    addBusyMessage: (msg: string) => string;

    removeBusyMessage: (token: string) => void;
}


export const Store: AppState = reactive({
    isBusy: false as boolean,
    busyMessage: null as string,

    addBusyMessage(msg: string) {
        const token = addBusyMesasge(msg);

        // Only change the busy message if there is not one already displayed
        if (!this.isBusy) {
            this.isBusy = true;
            this.busyMessage = msg;
        }

        return token;
    },

    removeBusyMessage(token: string) {
        removeBusyMesasge(token);

        const nextMsg = getNextBusyMessage();

        if (nextMsg) {
            this.isBusy = true;
            this.busyMessage = nextMsg;
        } else {
            this.isBusy = false;
            this.busyMessage = null;
        }
    }
});


// TODO: this busy queue should be moved

const busyQueue: { [key: string]: string; } = {};

function addBusyMesasge(msg: string): string {
    const token = alphaNumericToken();
    busyQueue[token] = msg;
    return token;
}

function removeBusyMesasge(token: string): void {
    delete busyQueue[token];
}

function hasBusyMessage(): boolean {
    return Object.keys(busyQueue).length > 0;
}

function getNextBusyMessage(): string {

    if (hasBusyMessage()) {
        return Object.values(busyQueue)[0];
    }

    return null;
}