import { RasterProperties } from './RasterProperties';

// A numeric value that will represent null
export const NULL_VALUE_CONSTANT = -995995;

export const DEFAULT_COLOR_MAP = "GreyOrange";

export const DEFAULT_RASTER_PROPERTIES: RasterProperties = {
    interpolatedDensity: true,
    negativeColorFill: false,
    negativeFill: false,
    positiveColorFill: false,
    positiveFill: false,
    wiggle: false
}

export const colorMap = [
    {
        name: "WhiteBlack",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAAAKCAYAAABmKbb7AAAAzklEQVRYR+1Y0RKAIAir//9lgQ5TD21Y1/MeurKUJk4YnmZmanaoar0sPPe2iIzvb/36eL/7uMlea1d77T+wX4Kj/jtgibZlgx1iSuz0OZcVO7KvOs1xNxfHB33XcCC/9/5xHUa/1b/BTvT7V//6ekgpcP0j7pOEwZuEhHkGECcfCZNEJhKGhKnhdoRrpqRZZjAl3akmzeckDAmTCXaK3qX4AGmYorc5hREGVKskjB4sq++jj20azkp7f08NQw0Dz2+S8zYS5m23UfT+Er0X+rVU8ViZRTkAAAAASUVORK5CYII="
    },
    {
        name: "RedWhiteBlack",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAAAKCAYAAABmKbb7AAAA80lEQVRYR+1Yyw7DIAwLKvT/f3G/UZJ1MuE1qqnbbQcfppAmMa7kJdDwEDll2yTEKLASo69h4SOWklv4+96fX2LI+1SXUt+j4XVMxOre0taN04RXYpXnxVaOnf/KvdXN7zLtVeqmmD2DmJ3ll3MWMxMzLfY4hj9iiJvkfNRcrFVUvUbVa1Z/zgE+4i0HfsNXBR4wHM/3uvL4Jg85A2PmO+O982icAgXjfwoKZhUgBXPtluwwS0dkhxljhiOpjDOMMY6k6ZzFM8z9WYeCoWB+OhxTMBQMBdOv+rxW1+v4/ajxa/t9HjsMOww7DDuMfyj8tw93L9ncNHkWNeTEAAAAAElFTkSuQmCC"
    },
    {
        name: "RedWhiteBlue",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAAAKCAYAAABmKbb7AAAA+klEQVRYR+1YWxLDIAhcpyb3v2KvEbF2eGhokt5gvxB5bWYIAuUNDLxeKLVCKWr1s1LlVbZtTpXf93V/k6neP7ttWzGmv+VTZREb8zwxJX8mC5w3GhgX/iv2aZe/JcUyuyTrn4LeB3oHWhvoAkiH3bXmdMn0Xk7e9QCRARHXlbBxPmSTmiz0NE74sliHxr3EyjhC91CMEdfwho7iPnk/z+8w/MezXVMcyY/hHkBhwvhPwYTJiTXAhGGFWRWRFSY9m3ySfisFnyT2MKs/sV6KPUw0wGx6vcFm03tOXZySYlrilPQwjnOs9vGaY3Xa0XAPk/Yc3MNwccfFHb5CZrcldCprOgAAAABJRU5ErkJggg=="
    },
    {
        name: "Saddleback",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAAAKCAYAAABmKbb7AAABSUlEQVRYR+2YQY4cIQxFHxioqZ6Rolwo979NFKmnawowRKZaUdIXyMYL9P43LC1jO/wIYQbATvyLpl+9vZFwvf3DeL2LIRBNB4gxEAKk9IxJQEwLRNPZfCQkFqVYLBJLRLa4tGxCepMrVi7mWyaWtHy6ZWRLxHIx7xuyFaRk8r4jpSC5kN9uSNmQtJHyTi4fpLQjslPyt8UU30nhnRy/k+IHwgeTwB3lPjo/R+Nzdj5H51DlV6uLR+/ca+NonbMrZ1UeX43alFqV42i0qtRTOY9GP5VWx8VHR6vS66A9GqOO5Re/OnoqoynDWMfS0+67Qp+L02I6QAfTvOlhusOczDFgPmNGTCswYb7Q7taZT756i0+CJ4wnzL9J4gnjFcaqjlcY/5L8S/IexnuY1Qh70+tNr09JPiX5WO1jtY/VvofxPYwv7v7j4u43TnGw239X35IAAAAASUVORK5CYII="
    },
    {
        name: "Angles5color",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAAAKCAYAAABmKbb7AAAA1ElEQVRYR+2Yyw6DIBBFjwsx+v/figEXNlBp6DjSbRd3YXCYR4ScjHCnE85EYGclsZAJdeztyFbny9PeW2xv9zk21qtnaxzMbFskhMyyJNZ1r2Oxy/Pks7EtzuaMalgfM5/96Nf/tDd2LaP1j/bX88EBZCBeY3LsnfLF33Elp8x5Pi+2r9/ntBqZScD4QAoYATPsUuowtoMJGAEz/K0JmBsgOsOMzkECRsDUjqJD7+0GpVvSExQCxr1yCxgBIx3m0sSKBiQd5hICJdy9hdJfwuA/AfMCTTh4Qjg7KvgAAAAASUVORK5CYII="
    },
    {
        name: "BlackRedYellowWhite",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAAAKCAYAAABmKbb7AAAA8UlEQVRYR+1Yyw7DIAwzlMLW/f/3MhnIyKi0bsdJPlRgQh5FVhIIAOoGIAGwkXOP9yGjPLv5ij/JaMNsmr0Vc9378jr09Y2NFnsEQu7f9gBCWTBlBYh3IN6mLB5ALAAKkI6hx31cHzpNz3AGIu1zHD7ecLKo7aTsDxhlQa0cdwQaQFqw6VLWT6VWBpcQgp1Sx/3jvOvMfV5m8+z8cP85jm5v+u3+iTOCCCPCrMQVYZRhXCZShhmJ2JVUlaRRulSSLvsg9TDqYVoGUdOrpveULXRL8rcm3ZIuy4kII8L8VE5EGBFGhHk9RdpD2Ly5/OPD3RM3F5/jakeqIgAAAABJRU5ErkJggg=="
    },
    {
        name: "GreyOrange",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAAAKCAYAAABmKbb7AAAA6klEQVRYR+1YQQ6DMAxzyh94In/f9oTRMDktrLRInHaZfEAl1HGCZKVJbVmWzczAJ6X0k3XnvuIHgGmaIm6Pa23i6N9z3Pm03MRexeq553mGAXg9H7Atw3yFeQbgsYa9OcC9XN6JA1faIMaBwOWy7yvgxFVf8gR34ftyl+/BTXzlGLj3vII7j7GqP3kS+Ssu4hN/2CWHYr/Lf7W5MZcmb5NgEvVyEqME04hZghmrmQSjChMVoz/udCSxcuhIOh0nfd+jHkY9zKkxV9OrpveYhDQlaUoaxnaN1Rqrb+9K2rsUCUaCkWD++OLuA42KXj4+b1QOAAAAAElFTkSuQmCC"
    },
    {
        name: "IntervalVelocity",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAAAKCAYAAABmKbb7AAAA+klEQVRYR+2YS4/CMAyEPyisWNjH//+PwIFd8WibFk3jLFHV697mYNmTcSIfrMSTFYwjO+ATJi97n2FxWhP3EfESFlf2i6+xzlgPwA9wCq9YdgYuwC9wDL+UV/YlvqKMPSCrcYlVwnfwimXilF9i+cMMb9so4Qbco0T56wyLV5klr2DlzrmSV505Av0mW2ogRfyHG+i2wTXQvr1i5ddcV3HT+hr6AboB2j77PsEjQZcyJ3sEJ15x0p6lPO0ZYeWGccOoWdwwcWv4hvENA/hJ8pPkGcYzjIfeaiD20LugmqySsmqySrKsnprAstqy2v8wWVJbVltW/8vH3RNZwGPpBJuTfAAAAABJRU5ErkJggg=="
    },
    {
        name: "IntervalVelocity16",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAAAKCAYAAABmKbb7AAAA+klEQVRYR+2YS4/CMAyEPyisWNjH//+PwIFd8WibFk3jLFHV697mYNmTcSIfrMSTFYwjO+ATJi97n2FxWhP3EfESFlf2i6+xzlgPwA9wCq9YdgYuwC9wDL+UV/YlvqKMPSCrcYlVwnfwimXilF9i+cMMb9so4Qbco0T56wyLV5klr2DlzrmSV505Av0mW2ogRfyHG+i2wTXQvr1i5ddcV3HT+hr6AboB2j77PsEjQZcyJ3sEJ15x0p6lPO0ZYeWGccOoWdwwcWv4hvENA/hJ8pPkGcYzjIfeaiD20LugmqySsmqySrKsnprAstqy2v8wWVJbVltW/8vH3RNZwGPpBJuTfAAAAABJRU5ErkJggg=="
    },
    {
        name: "IntervalVelocity32",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAAAKCAYAAABmKbb7AAAA+klEQVRYR+2YS4/CMAyEPyisWNjH//+PwIFd8WibFk3jLFHV697mYNmTcSIfrMSTFYwjO+ATJi97n2FxWhP3EfESFlf2i6+xzlgPwA9wCq9YdgYuwC9wDL+UV/YlvqKMPSCrcYlVwnfwimXilF9i+cMMb9so4Qbco0T56wyLV5klr2DlzrmSV505Av0mW2ogRfyHG+i2wTXQvr1i5ddcV3HT+hr6AboB2j77PsEjQZcyJ3sEJ15x0p6lPO0ZYeWGccOoWdwwcWv4hvENA/hJ8pPkGcYzjIfeaiD20LugmqySsmqySrKsnprAstqy2v8wWVJbVltW/8vH3RNZwGPpBJuTfAAAAABJRU5ErkJggg=="
    },
    {
        name: "Rainbow",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAAAKCAYAAABmKbb7AAABI0lEQVRYR+2Yy27EIAxFDwHS+f/fbKsukpE6eUB1eaTMdNmtF1GMfW0MsvDDZcjZAxEI7RM9N9pDnt2zrOAar+GyeHHAyaZksnXRg47sCt9l+suG9ur+RGGafrHffXS//l38Zx/3ybHkwMdxY8mRJekLfKa3Rs98pZl78txTZM2hrIVbc+T9uLESScWZAOWSArjp5bJ0SMl0IMm1FqYfSA6OdL/kV76H08MGPCbYHRwONgf7BCew+crvMuE65iF80yt0Wx+DnnhdZ7RfsA6+mz3tJxvCnE1WfJhwFjAWMDW4LGDshdGLYy9MS4c9ZVpKqmnLUlItFayGsRqmFqxW9P4tjK3otS6ptn7WJVlbfc0grK2u8w6bw9gcxuYwNrj77+DuB/TaUiQy+4/gAAAAAElFTkSuQmCC"
    },
    {
        name: "RedGreenBlue",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAAAKCAYAAABmKbb7AAABGklEQVRYR+2YwW7EIAxEH9IGSPYDeujf9X/3e5osBFcDSTeq2nMvPlhgj2eQIkQYgoFZBGZAoyIB91celqMm7FLvfcJOzrVPtSPv+n/1SaOv/Qbhg5V3CjuVxkrlyd7zcs1DY/uBfVI7R/0b+zdPGuKqXtlZ7ZUPbGgP3sAUD3swWWRqiWQzsUUmS0yWye3MI7nduVkaOJG8L50XLZHa0sebRea2MB0a6hUmvdhS1xNXa8WWyTZ3jvKsj2NQCqwrPJ9jrti2kSs0LyX0ufqE1zrqA/td4+wdGtb7rhpXXq2B4BvGN8y5SXzD6ETxE8ZPmOK/JP8l+R3G7zB+6fVLr7skd0luq91WH1baXZK7JH+H6Q957pL+zSV9AbTK4FGv5YSXAAAAAElFTkSuQmCC"
    },
    {
        name: "RedWhiteBlueExtremes",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAAAKCAYAAABmKbb7AAAA4UlEQVRYR+2VTQ6CMBSEPwg/Kw9g4k7v4P0vwBWMS3cu3ECJmActoIGEJsaFGRLSTjvvtYsv0+Ta0XGBpAIb+7mNFRxuQJpCWUKeD7/Ns2zSRTHp4Av7c73Ww7zznms1wfd5j/lZS+fa2g+/tgX7nYO6htYN2ua25vx+0Lbf+3zNWOd14+vGHn49aKv/rBl7L/Vo/D1m93rr8bzT7R9wBk4dHP1oegeJgPkuTQJGCRNFlIARMALGniY9SVEcbDYrYZQwm2Exo4ARMAJGT1IUA1FmJYwSRsAoYaIYiDL/e8K8ANd2aP3X7uW+AAAAAElFTkSuQmCC"
    },
    {
        name: "RedWhiteBlueHot",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAAAKCAYAAABmKbb7AAABGklEQVRYR+1Yu27DMAw81Xa2InPm9B/y/2OWfEGKTp1bBOliS1HBhxTaUz7gBoOmj+LRACE+Uq2o+AJwAVS25wJ8vx2AYUCaJpPDAOx2Jv1ZYWI3jh3fYmkcV/66z2myM4K3d+ETPfhTrPnYSo9R7R1bxb7hVr7ApefCf5ZHQikVpQDLUlEykAv027KY7Jh8z0/d7ICcK3I22+xnTHesScXcTnjcl3LNwrvhinG47SwxOq/G6zYS91O39/YfGv+8Pvfzu8ff/gaczsDHFTh+mhT9/Y7EhPHkZ8JoMjFh2q3BG6bfiLxhQtlkSYolyEopS1Lrb6JkD8Mehk0vm15OSZySwsjNsTqMrRyruYfhHoZ7mL6E5B7mpT3MPyGEpFErcgwDAAAAAElFTkSuQmCC"
    },
    {
        name: "RedYellowBlue",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAAAKCAYAAABmKbb7AAABF0lEQVRYR+1YUa4DIQgc0Bv1/herqM2AurbvHYGPDSIwSMIqIPOFCQVQBLMIZK3JowBQAapg66CuPcqpS9m9pj73t51jKNyu6mOz5bIwtt/b18a9qZ/r52wuX3tHHueYlTH9jcX3F+6J+ysWdT99CMwKRhd0U4yhaK2gGz+BtYrusoKx+NH17NGmd4XR1kirU7dfdoEbfrg2xw9f1kLX5Y3nIK9O25sYwdu7hqwXt4k1KXGrn5U4tCEueepsn5NxXXgR34PD9RwVkgmD50fJhDkJmAnzc1vmDZM3TDyh+STlk3RqpDsZ7hsja5isYc5NsYvhr2I8i94serNLyi4pWu5sq7Ot9rlGzmFyDpODu+tHyMHdf4O7D1gZjEJL2JozAAAAAElFTkSuQmCC"
    },
    {
        name: "SaddlebackHot",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAAAKCAYAAABmKbb7AAABXElEQVRYR+2YMXJcIRBEHwzw/65XZaWKpTvoDM50Ch1XV3DuyC6vd/8CAy74q8A3cDBBV3dDyNQwPa53Ot+BD5j8iQ/49sPhAIHJfsDd+VMPf4d4t2vvcA5C2NkLSHB4GYCQ/K7jfhYWjwwdPbIKEj0+ecLQy/Cy+2NAlogkIRwjPo37OBEOC5IiYUmEdUVSQmIiHY5IXKeWcCCmEyEcEVlJ8SsiB4L/QvAnkn9E/AOBEx3HmcrvpvxsmXOr/GmVqyq/SmZT5VIr51y4ViUXZSvK5ZqnzlnZtkq+VUpWbtdKuemE5ka5VDTvvl4qLTe0KDrut9234TellUYvSrtVug7dJrdcYfiBqlMzHrTWndvwjd508j+gwcA4n7oDSu/v8PQGr8AL8Hzn4R/AWcFYwVjBWIexDmNfkn1JNsPYDGNDrw29lpIsJY20ZCnJYrXFatvD2B7GFnf/1+LuLzre4EenLg7rAAAAAElFTkSuQmCC"
    },
    {
        name: "Spectrum",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAAAKCAYAAABmKbb7AAABGklEQVRYR+2YPXLEMAiFPxWS13uHvX9Ol9rWHxlAym6ROhWFBx48nhqNDKSv17fUPKgPoWdhFGhFuBUXYWT3NaZYeaMILTvvF1vNpB9e0zOG28LKU34rUB+TkTG9fZbWjEMo6abQKHROuTioH/jmkEpJjVOcdxhX8WU1ik8uijT7nltP6+g8WfpSjXfQXV+8buNzVpggE5jJrSSLOU4gIGPZnR/Os7jVf+TV37Gt+Ye2nyHrLCGpr7oVt91tam8f9TWmuc1TrsbqqlfcFmfljPupt/X70t88xaoVFyYuTFyYeGH8JYoXJn5J8Uta/VL0MO/+KHqY7M1xNL3R9MaUFFNSjNU2psdY7WN7jNWxh7FdTexhYnH3H4u7Hx1mtGiVfGhjAAAAAElFTkSuQmCC"
    }
];