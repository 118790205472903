import { SeismicTraceProcessor } from "@int/geotoolkit/seismic/pipeline/processor/SeismicTraceProcessor";
import { SeismicMetaData } from "@int/geotoolkit/seismic/data/SeismicMetaData";
import { SeismicPipeline } from "@int/geotoolkit/seismic/pipeline/SeismicPipeline";
import {obfuscate} from '@int/geotoolkit/lib';
import { DataStatistics } from "@int/geotoolkit/seismic/analysis/maths/DataStatistics";
import { NULL_VALUE_CONSTANT } from "../common/Constants";

export class StatisticsProcessor extends SeismicTraceProcessor {

    min?: number = undefined;
    max?: number = undefined;
    // The running average
    average?: number = undefined;
    // The running RMS
    rms?: number = undefined;

    // Stop collecting stats when we hit this number
    sample_size = 10000000;
    traces_sampled = 0;
    processingTime = 0;

    constructor(state: any) {       
        super(state);
        this.setState(state);
    }

    setState(state: object | { name?: string, apply?: boolean } ): this {
        super.setState(state);
        return this;
    }

    process(pipeline: SeismicPipeline, metadata: SeismicMetaData, dataIn: Float32Array, dataOut: Float32Array): boolean {

        if (dataIn.length == 0) {
            return false;
        }

        // If we've finished our stats calculation, just pass the data through
        if (this.traces_sampled >= this.sample_size) {
            for (let i = 0, il = dataIn.length; i < il; i++) {
                dataOut[i] = dataIn[i];
            }
            return false;
        }

        // If this is our null value, don't include this trace in stats, just pass the data through
        if (dataIn[0] == NULL_VALUE_CONSTANT) {
            for (let i = 0, il = dataIn.length; i < il; i++) {
                dataOut[i] = dataIn[i];
            }
            return false;
        }

        // Update statistics

        const startTime = Date.now();
        this.traces_sampled++;

        // set some initial stat values if they haven't been set yet
        this.min = this.min == null ? dataIn[0] : this.min;
        this.max = this.max == null ? dataIn[0] : this.max;
        this.average = this.average == null ? dataIn[0] : this.average;
        this.rms = this.rms == null ? dataIn[0] : this.rms;

        for (let i = 0, il = dataIn.length; i < il; i++) {

            // pass data through
            dataOut[i] = dataIn[i];

            // capture stats

            // update min
            if (dataIn[i] < this.min) {
                this.min = dataIn[i];
            }

            // update max
            if (dataIn[i] > this.max) {
                this.max = dataIn[i];
            }
        }

        // Don't crash the pipeline if something goes wrong
        try {
            // Average of this trace
            const avg = DataStatistics.sum(dataIn, 0, dataIn.length - 1) / dataIn.length;

            // RMS of this trace
            const rms = Math.sqrt(dataIn.reduce((a, b) => a + Math.pow(b, 2), 0) / dataIn.length);

            // Update the running average
            this.average = this.average + ((avg - this.average) / this.traces_sampled);

            // Update the running RMS
            this.rms = this.rms + ((rms - this.rms) / this.traces_sampled);

            //console.log(`avg: [${avg} : ${this.average}] - rms: [${rms} : ${this.rms}]`);
        } catch (err) {
            console.error(`Failed to compute trace statistics: ${err}`);
        }

        this.processingTime = this.processingTime + (Date.now() - startTime);

        // Set statistics as we go
        pipeline.setStatistics({
            "min": this.min,
            "max": this.max,
            "average": this.average,
            "rms": this.rms
        });

        // Set the normalization as we go
        pipeline.setNormalization({
            limits: {
                min: this.min,
                max: this.max
            }
        });

        return true;
    }

    getDataStatistics(): any {
        console.log(`** On-the-fly stats **`);
        console.log(`min: ${this.min}`);
        console.log(`max: ${this.max}`);
        console.log(`average: ${this.average}`);
        console.log(`rms: ${this.rms}`);
        console.log(`processing time: ${this.processingTime / 1000} seconds`);
        

        return {
            "min": this.min,
            "max": this.max,
            "average": this.average,
            "rms": this.rms
        }
    }
}

obfuscate(StatisticsProcessor); 