<template>
    <div id="app" class="app-root">
        <HeaderView />
        <SeismicView v-if="show2d" :provider="provider" :productId="productId" :projectId="projectId" :lineName="lineName" />
        <!--<div v-if="show3d">dummy</div>-->
        <SeismicView3D v-if="show3d" :provider="provider" :productId="productId" :projectId="projectId" />
        <div v-if="errorMsg">
            <span>{{ errorMsg }}</span>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import HeaderView from './components/HeaderView.vue';
    import SeismicView from './components/SeismicView.vue';
    import SeismicView3D from './components/SeismicView3D.vue';

    export default defineComponent({
        name: 'App',        
        components: {
            HeaderView,
            SeismicView,
            SeismicView3D
        },
        created() {
            this.getAndValidateQueryStringParameters();
        },
        mounted() {
            console.log(`the App component is now mounted.`);
        },        
        data() {
            return {
                is3d: false,
                productId: null as string,
                projectId: null as string,
                provider: null as string,
                errorMsg: null as string,
                lineName: null as string
            }
        },
        computed: {
            show3d(): boolean {
                return !this.errorMsg && this.is3d;
            },
            show2d(): boolean {
                return !this.errorMsg && !this.is3d;
            }
        },
        methods: {
            getAndValidateQueryStringParameters() {

                const is3dParam = getQueryStringParam("is3d");
                const providerParam = getQueryStringParam("provider");
                const productIdParam = getQueryStringParam("productId");
                const projectIdParam = getQueryStringParam("projectId");
                const lineNameParam = getQueryStringParam("lineName");

                if (is3dParam === null) {
                    this.errorMsg = "Bad Request: is3d parameter missing.";
                    return;
                }

                if (providerParam === null) {
                    this.errorMsg = "Bad Request: provider parameter missing.";
                    return;
                }

                if (!isProviderValid(providerParam)) {
                    this.errorMsg = `Bad Request: provider '${providerParam}' is not a valid provider.`;
                    return;
                }

                if (productIdParam === null) {
                    this.errorMsg = "Bad Request: productId parameter missing.";
                    return;
                }

                if (providerParam.toUpperCase() === "TGS" && projectIdParam === null) {
                    this.errorMsg = "Bad Request: projectId parameter missing.";
                    return;
                }

                if (providerParam.toUpperCase() === "TGS" && is3dParam.toLowerCase() === "false" && lineNameParam === null) {
                    this.errorMsg = "Bad Request: lineName parameter missing.";
                    return;
                }

                // Return true if url param exists and equals case-insensitive value 'true'
                this.is3d = /^true$/i.test(is3dParam);
                this.provider = providerParam.toUpperCase();
                this.productId = productIdParam;
                this.projectId = projectIdParam;
                this.lineName = lineNameParam;
            }
        }
    });

    /** Validade that the passed in provider parameter is one the is supported */
    function isProviderValid(provider: string): boolean {

        if (!provider) {
            return false;
        }

        const validProviders = ["PGS", "TGS"];
        provider = provider.toUpperCase();

        return validProviders.includes(provider);
    } 

    /** Helper function to get a url parameter by name */
    function getQueryStringParam(name: string): string {
        const queryParams = window.location.search;
        const searchParams = new URLSearchParams(queryParams);        
        return searchParams.get(name);
    }
</script>

<style>
    body {
        margin: 0;
        font-family: 'GothamNarrowBook', Arial, sans-serif;
        overflow: hidden;
    }

    .app-root {
        display: grid;
        grid-template-rows: 65px auto;
        height: 100vh;               
    }

    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
    }
</style>