<template>
    <div class="seismic-view-root">
        <ToolbarView />
        <div ref="plotContainer" class="plot-container">
            <canvas ref="seismicViewCanvas" class="seismic-view-canvas"> </canvas>
        </div>
    </div>
</template>

<style>
    .seismic-view-root {
        background-color: #dbdcdd;
        display: grid;
        grid-template-rows: 54px auto;
    }
    .seismic-view-root .seismic-view-canvas {
        overflow: hidden;
        border: 1px solid #F0F0F0;
        background-color: white;
    }

    .seismic-view-root .button-container {
        width: 100%;
        margin: 5px 0;
    }

    .seismic-view-root .plot-container {
        width: 100%;
        margin-top: 3px;
    }

</style>


<script lang="ts">
    import { defineComponent } from 'vue';
    import ToolbarView from './ToolbarView.vue';
    import { EventManager } from "../events/EventManager";
    import * as Constants from "../common/Constants";

    // INT libraries
    import { SeismicPipeline } from "@int/geotoolkit/seismic/pipeline/SeismicPipeline";
    import { SeismicColors } from "@int/geotoolkit/seismic/util/SeismicColors";
    import { SeismicWidget } from "@int/geotoolkit/seismic/widgets/SeismicWidget";
    import { NormalizationType } from "@int/geotoolkit/seismic/pipeline/NormalizationType";
    import { Plot } from "@int/geotoolkit/plot/Plot";
    import { StatisticsProcessor } from "../trace-processors/StatisticsProcessor";
    import { RasterProperties } from '@/common/RasterProperties';
    import { ManipulatorType } from '@int/geotoolkit/seismic/widgets/SeismicViewWidget';
    import { MemoryReader } from '@int/geotoolkit/seismic/data/MemoryReader';
    import { Events as NodeEvents } from '@int/geotoolkit/scene/Node';
    import { createSeismicWidget } from "../ui/Seismic2dWidget";
    import { getMemoryReader } from "../readers/Seismic2dMemoryReader";


    export interface Statistics {
        min: number;
        max: number;
        rms: number;
        average: number;
    }

    interface Scale {
        x: number;
        y: number;
    }


    let plot: Plot;
    let canvasElement: HTMLCanvasElement;
    let plotContainer: HTMLDivElement;
    let widget: SeismicWidget;
    let originalScale: Scale;

    const PLOT_MARGIN = 5;
    let lastYValue = 0;

    export default defineComponent({
        name: 'SeismicView',
        components: {
            ToolbarView
        },
        props: {
            provider: String,
            productId: String,
            projectId: String,
            lineName: String
        },
        data() {
            return {
                
            };
        },

        created() {
   
        },

        mounted() {
            // Grab the HTML Elements we need
            canvasElement = this.$refs["seismicViewCanvas"] as HTMLCanvasElement;
            plotContainer = this.$refs["plotContainer"] as HTMLDivElement;

            // Hook up tools
            EventManager.on("ZoomInToolClicked", () => this.zoomIn());
            EventManager.on("ZoomOutToolClicked", () => this.zoomOut());
            EventManager.on("RubberBandZoomToolClicked", () => this.rubberBandZoom());
            EventManager.on("ZoomResetToolClicked", () => this.resetZoom());
            EventManager.on("ZoomFitToolClicked", () => this.zoomFit());
            EventManager.on("ColorMapChanged", (color) => this.onColorChangedEvent(color as string));
            EventManager.on("RasterPropertyChanged", (props) => this.updatePlotRasterProperty(props as RasterProperties));

            EventManager.on("scaleYClicked", (isIncrease) => this.scaleY(isIncrease as boolean));
            EventManager.on("scaleResetClicked", () => this.scaleReset());

            window.addEventListener('resize', this.resize);

            this.getFileUrlInputParameterAndKickOffVisualization();
        },

        unmounted() {
            window.removeEventListener('resize', this.resize);
        },
        
        methods: {            

            getFileUrlInputParameterAndKickOffVisualization(): void {   
                this.visualizeSeismic(this.provider);
            },

            async visualizeSeismic(provider: string): Promise<void> {
                // Clear existing stuff before creating a new visualization.
                this.clear();               

                //const reader = await createMemoryReader(this.productId);
                const reader = await getMemoryReader(provider, this.productId, this.projectId, this.lineName);

                console.log("reader created");

                // create pipeline              
                const pipeline = this.createSeismicPipeline(reader);
                
                console.log("pipeline created");                
                
                // Create a widget to display a model
                widget = createSeismicWidget(pipeline);

                console.log("widget created");

                // Watch for scale changes to inform toolbar tools
                widget.on(NodeEvents.VisibleLimitsChanged, (event: any, sender: any, args: any) => {
                    const modelLimits = args["visibleModelLimits"];

                    if (this.hasVerticalScaleChanged(modelLimits.height)) {
                        this.publishVerticalScaleChangedEvent();
                    }
                });

                // Create a new Plot object from the canvas and group
                plot = new Plot({
                    canvasElement: canvasElement,
                    root: widget,
                    autosize: false
                });

                console.log("plot created");

                // Call manually to set the initial size
                this.resize();

                // Set to a small size for faster loading. User can zoom in if they want
                //plot.setSize(600, 400);

                // Start at full extent
                widget.fitToBounds();

                this.publishVerticalScaleChangedEvent();
            },
                    

            createSeismicPipeline(reader: MemoryReader): SeismicPipeline {

                const pipeline = new SeismicPipeline("Seismic", reader, reader.getStatistics());

                // Attach a custom trace processpr to calculate statistics on the fly
                const statsProcessor = new StatisticsProcessor({ "apply": true, name: "StatisticsProcessor" });
                pipeline.addTraceProcessor(statsProcessor);

                pipeline.setOptions({
                    //maximumTracesPerPixel: 1,// Does this do anything?
                    //fetch: {
                    //    fetchsize: 1000
                    //},
                    plot: {
                        type: {
                            Wiggle: Constants.DEFAULT_RASTER_PROPERTIES.wiggle,
                            InterpolatedDensity: Constants.DEFAULT_RASTER_PROPERTIES.interpolatedDensity,
                            PositiveFill: Constants.DEFAULT_RASTER_PROPERTIES.positiveFill,
                            NegativeFill: Constants.DEFAULT_RASTER_PROPERTIES.negativeFill,
                            PositiveColorFill: Constants.DEFAULT_RASTER_PROPERTIES.positiveColorFill,
                            NegativeColorFill: Constants.DEFAULT_RASTER_PROPERTIES.negativeColorFill
                        },
                        //densityDecimation: true,
                        //decimationSpacing: 60 // Does this do anything?
                    },
                    colors: {
                        colorMap: SeismicColors.getDefault().createNamedColorMap(Constants.DEFAULT_COLOR_MAP)
                    }
                });

                //pipeline.getColorMap().setTraceStatusColor(Status.Highlighted, new RgbaColor(0, 255, 0));
                //pipeline.setTraceStatus(5, Status.Highlighted);

                // Wait until we have recieved some data and our custom trace processor has sampled data and calculated statistics
                pipeline.await(() => {
                    console.log(`data fetch complete`);
                    const newStats: Statistics = statsProcessor.getDataStatistics();
                    pipeline.setStatistics(newStats);

                    pipeline.setOptions({
                        normalization: {
                            type: NormalizationType.RMS,
                            scale: 0.4
                        }
                    });
                });

                return pipeline;
            },


            resize() {

                // If plot is null just return
                if (!plot?.isDisposed) {
                    return;
                }
                
                if (plot && !plot.isDisposed()) {
                    plot.setSize(plotContainer.clientWidth - PLOT_MARGIN, plotContainer.clientHeight - PLOT_MARGIN);
                }
            },

            scaleY(isIncrease: boolean): void {

                if (!widget) {
                    return;
                }

                const opts: any = widget.getScaleOptions();
                const oldScale = { x: opts["tracescale"], y: opts["samplescale"] } as Scale;

                if (!originalScale) {
                    originalScale = { x: oldScale.x, y: oldScale.y } as Scale;
                }

                const amount = isIncrease ? 1 : -1;

                // Don't let the user scale below the original value, that is what fits fullscreen. Going smaller it pointless. Going negative is bad.
                const options = {
                    samplescale: Math.max(originalScale.y, oldScale.y + amount)
                };

                widget.setScaleOptions(options, true);
            },

            scaleReset(): void {

                if (!widget || !originalScale) {
                    return;
                }

                const options = {
                    tracescale: originalScale.x,
                    samplescale: originalScale.y
                };

                widget.setScaleOptions(options, false);
            },

            hasVerticalScaleChanged(yValue: number): boolean {
                if (Math.abs(yValue - lastYValue) >= 0.0001) {
                    lastYValue = yValue;
                    return true;
                }
                return false;
            },

            publishVerticalScaleChangedEvent(): void {
                const opts: any = widget.getScaleOptions();

                // in z unit per device unit if depth data (e.g feet per inch), or in device unit per z unit (e.g inches per second)
                const sampleScale = opts["samplescale"];
                //const deviceUnit = opts["deviceunit"]["name"];

                //console.log(`getScaleFactor: ${widget.getPipeline().getScaleFactor()} - getScaleOptions: ${JSON.stringify(opts)}`); 
                EventManager.emit("SeismicWidgetScaleChanged", sampleScale);
            },

            // Tools
            zoomIn(): void {
                if (widget) {
                    widget.zoomIn();
                }
            },

            zoomOut(): void {
                if (widget) {
                    widget.zoomOut();
                }
            },

            zoomFit(): void {
                if (widget) {
                    this.resize();
                    widget.fitToBounds();
                }
            },

            resetZoom(): void {
                if (widget) {
                    widget.resetZoom();
                }
            },

            rubberBandZoom(): void {
                widget.setManipulatorType(ManipulatorType.RubberBand, true);
            },

            onColorChangedEvent(color: string): void {
                if (widget != null && widget.getPipeline() != null) {
                    widget.getPipeline().setOptions({
                        colors: {
                            colorMap: SeismicColors.getDefault().createNamedColorMap(color)
                        }
                    });
                }
            },

            updatePlotRasterProperty(rasterProperties: RasterProperties): void {
                if (widget != null && widget.getPipeline() != null) {
                    widget.getPipeline().setOptions({
                        plot: {
                            type: {
                                Wiggle: rasterProperties.wiggle,
                                InterpolatedDensity: rasterProperties.interpolatedDensity,
                                PositiveFill: rasterProperties.positiveFill,
                                NegativeFill: rasterProperties.negativeFill,
                                PositiveColorFill: rasterProperties.positiveColorFill,
                                NegativeColorFill: rasterProperties.negativeColorFill
                            }
                        }
                    });
                }
            },            

            

            /** Clean up and dispose of everything. */
            clear(): void {
                // set the canvas back to an initial small size        
                canvasElement.style.height = "100px";
                canvasElement.style.width = "100px";

                // Dispose the plot
                if (!!plot) {
                    plot.dispose(false); // false, we'll dispose the root (widget) manually.
                }

                // Clear the Canvas
                const context = canvasElement.getContext("2d");

                // TODO check context for null?

                context.clearRect(0, 0, canvasElement.width, canvasElement.height);

                // Disconnect handlers/listeners
                // None 

                // Clear/dispose everything I can think of
                if (!!widget) {

                    if (!!widget.getPipeline()) {

                        if (!!widget.getPipeline().getReader()) {
                            widget.getPipeline().getReader().dispose();
                        }

                        widget.getPipeline().clear();
                        widget.getPipeline().dispose();
                    }

                    widget.clearCache();
                    widget.dispose();
                }
            }

        }

    });
</script>
