import { MemoryReader } from "@int/geotoolkit/seismic/data/MemoryReader";
import { TraceBoundsDto } from "../dto/TraceBoundsDto";
import { TraceDto } from "../dto/TraceDto";
import * as TgsProvider from "../data-providers/TgsProvider";
import { Store } from "../common/AppState";
import { PipelineQuery } from "../common/PipelineQuery";

/**
 * 
 * @param projectId The project id of the seismic product
 * @param productId The id of the seismic product
 * @param lineName The linename associated with the seismic product
 * @returns A MemoryReader
 */
export function createMemoryReader(productId: string, projectId: string, lineName: string): Promise<MemoryReader> {

    return new Promise((resolve) => {

        const msgToken = Store.addBusyMessage(`Retrieving trace bounds`);

        // Get the trace bounds, needed for creating the memory reader
        TgsProvider.getTraceBounds(productId, projectId, lineName).then((bounds: TraceBoundsDto) => {

            Store.removeBusyMessage(msgToken);

            console.log(bounds);
            console.log(`Create Reader`);

            const reader = new MemoryReader({
                numberOfTraces: bounds.numberOfTaces,
                numberOfSamples: bounds.sampleCount,
                //startValue: bounds.twoDeeRange.traceRange.min,
                sampleRate: 1,
            });

            const traceProcessor = getTraceProcessor(bounds, productId, projectId, lineName);
            reader.setTraceProcessor(traceProcessor);

            resolve(reader);
        }).catch((err) => {
            Store.removeBusyMessage(msgToken);
            console.error(`Error retrieving trace bounds`, err);
        });
    });
}

// Create the trace processor that will be called by the pipeline/memoryreader to get trace data from the Api
function getTraceProcessor(bounds: TraceBoundsDto, productId: string, projectId: string, lineName: string) {
    return {
        getAsyncData: (queryFromPipeline: PipelineQuery, callback: any) => {

            // Something makes a request at the start with params from: 0, to:255, traceIndexes: undefined.  Don't know why, but we have to handle this edge case
            if (!queryFromPipeline.traceIndexes) {
                queryFromPipeline.traceIndexes = [];
                queryFromPipeline.traceIndexes.push(queryFromPipeline.from);
                queryFromPipeline.traceIndexes.push(queryFromPipeline.from + 1);
            }

            // At this point we have the trace indexes the the geotoolkit is requesting

            const from = queryFromPipeline.from + bounds.twoDeeRange.traceRange.min;
            let to = queryFromPipeline.to + bounds.twoDeeRange.traceRange.min;
            const step = queryFromPipeline.traceIndexes[1] - queryFromPipeline.traceIndexes[0];

            if (to > bounds.twoDeeRange.traceRange.max) {
                to = bounds.twoDeeRange.traceRange.max;
            }

            const msgToken = Store.addBusyMessage(`Retrieving traces ${queryFromPipeline.from}-${queryFromPipeline.to}`);
            console.log(`Retrieving traces ${from}-${to}`);

            TgsProvider.getTraces2d(productId, from, to, step, projectId, lineName).then((tracesData: TraceDto[]) => {

                Store.removeBusyMessage(msgToken);

                // Fire the callback that was passed to us
                const callbackProcessor = getCallbackProcessor(tracesData, queryFromPipeline);
                callback(callbackProcessor);

            }).catch((err) => {
                Store.removeBusyMessage(msgToken);
                console.error(`Error retrieving traces ${from}-${to}`, err);
            });

        },
        getDataStatistics: () => {
            const dummyStats = { "min": 1000, "max": -1000, "average": 5, "rms": 5 };
            return dummyStats;
        },
        getTraceHeader: (a: any, b: any, c: any) => {
            console.log(`${a}-${b}-${c}`);
        },
        getTraceData: (a: any, b: any, c: any) => {
            console.log(`${a}-${b}-${c}`);
        }
    }
}

// Create the handler for the callback that will be called by the pipeline/memoryreader to set the data on the traces once retrieved
function getCallbackProcessor(tracesData: TraceDto[], queryFromPipeline: PipelineQuery) {
    return {
        getTraceData: (reader: MemoryReader, trace: any, traceId: any) => {

            // Index of the traceId in the trace index array that the pipeline is requesting
            const index = queryFromPipeline.traceIndexes.indexOf(traceId);

            if (index == -1 || index >= tracesData.length) {
                // No trace data, zero out samples
                for (let i = 0; i < reader.getNumberOfSamples(); i++) {
                    trace[i] = 0;
                }
            } else {
                for (let i = 0; i < reader.getNumberOfSamples(); i++) {
                    // Grab the trace data from the results and the same index
                    trace[i] = tracesData[index].samples[i];
                }
            }
        }
    }
}