import { MemoryReader } from "@int/geotoolkit/seismic/data/MemoryReader";
import { TraceBoundsDto } from "../dto/TraceBoundsDto";
import { TraceDto } from "../dto/TraceDto";
import * as PgsProvider from "../data-providers/PgsProvider";
import { Store } from "../common/AppState";
import { PipelineQuery } from "../common/PipelineQuery";
import { SliceType } from "../common/SliceType";
import { NULL_VALUE_CONSTANT } from "../common/Constants";

/**
 * 
 * @param productId The id of the sesmic product
 * @param sliceType The type of slice this reader is for. i.e. Inline or Crossline
 * @param sliceLocation The inline or crossline value of the slice.  i.e. If the sliceType is Crossline 
 *  and the sliceLocation is 56, we create a slice using all the inline traces at crossline 56.
 * @returns A MemoryReader
 */
export function createMemoryReaderForSlice(productId: string, sliceType: SliceType, sliceLocation: number): Promise<MemoryReader> {

    return new Promise((resolve) => {

        const msgToken = Store.addBusyMessage(`Retrieving trace bounds`);

        PgsProvider.getTraceBounds(productId).then((bounds: TraceBoundsDto) => {

            Store.removeBusyMessage(msgToken);

            console.log(bounds);
            console.log(`Create Reader`);

            const minTrace = sliceType == SliceType.INLINE ? bounds.threeDeeRange.crosslineRange.min : bounds.threeDeeRange.inlineRange.min;
            const maxTrace = sliceType == SliceType.INLINE ? bounds.threeDeeRange.crosslineRange.max : bounds.threeDeeRange.inlineRange.max;
            const step = sliceType == SliceType.INLINE ? bounds.threeDeeRange.crosslineRange.step : bounds.threeDeeRange.inlineRange.step;

            const reader = new MemoryReader({
                numberOfTraces: (maxTrace - minTrace) / step,
                numberOfSamples: bounds.sampleCount,                
                sampleRate: 1,
                nullValue: NULL_VALUE_CONSTANT
            });

            const traceProcessor = getTraceProcessor(productId, sliceType, sliceLocation, minTrace, step);
            reader.setTraceProcessor(traceProcessor);

            resolve(reader);
        }).catch((err) => {
            Store.removeBusyMessage(msgToken);
            console.error(`Error retrieving trace bounds`, err);
        });
    });
}

// Create the trace processor that will be called by the pipeline/memoryreader to get trace data from the Api
function getTraceProcessor(productId: string, sliceType: SliceType, sliceLocation: number, minTrace: number, step: number) {
    return {
        getAsyncData: (queryFromPipeline: PipelineQuery, callback: any) => {
            //console.log(queryFromPipeline);

            const from = queryFromPipeline.from + minTrace;
            const to = queryFromPipeline.to + minTrace;

            const msgToken = Store.addBusyMessage(`Retrieving traces ${from}-${to}`);            
            
            const sliceTypeString = sliceType == SliceType.CROSSLINE ? "CROSSLINE" : "INLINE";

            PgsProvider.get3dSlice(productId, from, to, step, sliceTypeString, sliceLocation).then((tracesData: TraceDto[]) => {

                console.log(`Requested traces ${from}-${to}: ${tracesData.length} traces returned`);

                Store.removeBusyMessage(msgToken);

                // Fire the callback that was passed to us
                const callbackProcessor = getCallbackProcessor(tracesData, queryFromPipeline, minTrace, sliceType);
                callback(callbackProcessor);

            }).finally(() => {
                Store.removeBusyMessage(msgToken);
            });
        },
        getDataStatistics: (x: any) => {
            console.log(`stats called ${x}`);

            return { // dummmy stats
                'average': 0,
                'min': -1,
                'max': 1,
                'rms': Math.sqrt(2) / 2
            };
        },
        getTraceHeader: (a: any, b: any, c: any) => {
            console.log(`getTraceHeader ${a}-${b}-${c}`);
        },
        getTraceData: (a: any, b: any, c: any) => {
            console.log(`getTraceData ${a}-${b}-${c}`);
        }
    }
}

// Create the handler for the callback that will be called by the pipeline/memoryreader to set the data on the traces once retrieved
function getCallbackProcessor(tracesData: TraceDto[], queryFromPipeline: PipelineQuery, minTrace: number, sliceType: SliceType) {
    return {
        getTraceData: (reader: MemoryReader, trace: number[], traceId: number) => {

            // Find the index of the requested trace id in the result data
            const resultIndex = sliceType == SliceType.INLINE ? tracesData.map(x => x.crossline).indexOf(traceId + minTrace) : tracesData.map(x => x.inline).indexOf(traceId + minTrace)

            if (resultIndex != -1) {
                for (let i = 0; i < reader.getNumberOfSamples(); i++) {
                    trace[i] = tracesData[resultIndex].samples[i];
                }
            } else {
                for (let i = 0; i < reader.getNumberOfSamples(); i++) {
                    trace[i] = NULL_VALUE_CONSTANT;
                }
            }
        }
    }
}