// settings.production.ts  Used in the azure production environment

import { AppSettings } from "./AppSettings";

export const config: AppSettings = {
    clientId: "09b6dd38-2b45-4136-bf8a-b524aabe2f8a",
    pgsApi: "https://pgs-wrapper-prod-cvx.azurewebsites.net/api/pgs",
    tgsApi: "https://tgs-wrapper-prod.azurewebsites.net/api/tgs",
    pgsWrapperScope: "https://explore.azure.chevron.com/user_impersonation",
    tgsWrapperScope: "https://explore.azure.chevron.com/user_impersonation"
}