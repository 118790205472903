import { MemoryReader } from "@int/geotoolkit/seismic/data/MemoryReader";


import { createMemoryReader as createPgsMemoryReader } from "./Pgs2dMemoryReader";
import { createMemoryReader as createTgsMemoryReader } from "./Tgs2dMemoryReader";


export function getMemoryReader(provider: string, productId: string, projectId: string, lineName: string): Promise<MemoryReader> {

    switch (provider) {
        case "PGS":
            return createPgsMemoryReader(productId);
        case "TGS":
            return createTgsMemoryReader(productId, projectId, lineName);
        default:
            console.error(`Provider '${provider}' not supported.`);
            return Promise.reject(`Provider '${provider}' not supported.`);
    }
}
