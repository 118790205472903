import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/loading.gif'


const _hoisted_1 = { class: "spinner-container" }
const _hoisted_2 = {
  class: "spinner-image",
  src: _imports_0,
  alt: "spinner..."
}
const _hoisted_3 = { class: "spinner-msg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("img", _hoisted_2, null, 512), [
      [_vShow, _ctx.Store.isBusy]
    ]),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.Store.busyMessage), 1)
  ]))
}