import { FillStyle } from "@int/geotoolkit/attributes/FillStyle";
import { CrossHair } from "@int/geotoolkit/controls/tools/CrossHair";
import { Alignment } from "@int/geotoolkit/layout/BoxLayout";
import { FieldDesc } from "@int/geotoolkit/seismic/data/FieldDesc";
import { SeismicPipeline } from "@int/geotoolkit/seismic/pipeline/SeismicPipeline";
import { SeismicWidget } from "@int/geotoolkit/seismic/widgets/SeismicWidget";
import { AnchorType } from "@int/geotoolkit/util/AnchorType";
import { RgbaColor } from "@int/geotoolkit/util/RgbaColor";


export function createSeismicWidget(pipeline: SeismicPipeline): SeismicWidget {

    const widgetOptions = {};

    addColorBarOptions(widgetOptions);
    //this.addHeaderTableOptions(widgetOptions, fields);
    //this.addAuxiliaryChartOptions(widgetOptions);
    addStatusBarOptions(widgetOptions);

    const widget = new SeismicWidget(pipeline, widgetOptions);

    const headerFields = pipeline.getReader().getTraceHeaderFields();

    let headerInfo: any; // What's up with this?

    for (let i = 0; i < headerFields.length; i++) {
        const header = headerFields[i];

        if (header.getName() === 'TraceNumber') {
            widget.setTraceHeaderVisible(header, true);
        }

        headerInfo = widget.getTraceHeaderAxis(header);

        if (headerInfo) {
            headerInfo['label'].getTextStyle().setColor("#6b6b6b");
        }
    }

    addTooltipToCrosshairTool(widget);

    return widget;
}

function addColorBarOptions(options: any): void {
    options["colorbar"] = {
        maxheight: '90%',
        alignment: Alignment.Center,
        axis: {
            size: 70
        }
    }
}

function addStatusBarOptions(options: any): void {

    options["statusbar"] = {
        autosize: false,
        sizes: {
            small: 100
        }
    }
}

function addTooltipToCrosshairTool(widget: SeismicWidget): void {

    // Customize the default cross hair tool
    const crossHair = widget.getToolByType(CrossHair) as CrossHair;
    const manipulatorLayer = crossHair.getManipulatorLayer();
    // Create some new crossHairSettings
    const crossHairSettings = {
        group: manipulatorLayer,
        center: {
            textstyle: { 'font': '12px sans-serif' },
            visible: true,
            alignment: AnchorType.LeftBottom,
            border: {
                visible: true,
                fillstyle: new FillStyle(new RgbaColor(255, 255, 255, 164)),
                linestyle: "black",
                padding: 4,
                radius: 2
            },
            textconverter: function (x: any, y: any) {
                return `TraceId: ${Math.round(x)} \nTime: ${Math.round(y * 100) / 100}s`;
            }
        },
        north: {
            visible: false
        },
        south: {
            visible: false
        },
        west: {
            visible: false
        },
        east: {
            visible: false
        },
        linestyle: 'red',
        vertical: {
            color: 'gray',
            pattern: [6, 4],
            pixelsnapmode: true
        },
        horizontal: {
            color: 'gray',
            pixelsnapmode: true
        }
    };

    // Set the new crossHairSettings on the crossHair tool
    crossHair.setSettings(crossHairSettings);
}


function addHeaderTableOptions(options: any, fields: FieldDesc[]): void {

    options["table"] = {
        visible: 'visible',
        size: 130,
        headers: fields.map(x => x.getName())
    }
}

function addAuxiliaryChartOptions(options: any): void {

    options["auxiliarychart"] = {
        size: 120,
        title: {
            text: 'Auxiliary Chart',
            textstyle: {
                font: '16px Roboto',
                color: 'gray'
            },
            size: 20
        },
        charts: [{
            name: 'CDPY',
            linestyle: 'green'
        }, {
            name: 'TSSN',
            linestyle: 'blue'
        }]
    }
}